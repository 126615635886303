import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "./auth.service";
import { Observable, Observer } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { SecureSimpleStorage } from "src/data/disk-data-source/secure-simple-storage";
import { Contract } from "src/data/disk-data-source/contract";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  constructor(private authService: AuthService, public router: Router, private secureStorage: SecureSimpleStorage) {}

  canActivate(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.authService.isLogged().subscribe((logged) => {
        if (!logged) {
          const currentPath = window.location.pathname;
          if (currentPath.includes('checkout')) {
              let prefix = '/design';
              if (currentPath.startsWith(prefix)) {
                  this.secureStorage
                      .save(
                          Contract.lastRouteActive,
                          currentPath.slice(prefix.length)
                      )
                      .toPromise();
              } else {
                  this.secureStorage
                      .save(Contract.lastRouteActive, currentPath)
                      .toPromise();
              }
          }
          if (environment.rol === "Corporativo") {
            this.router.navigate(["/login-corporate"]);
          }
          if (environment.rol === "Individual" || environment.rol === "Interno" || environment.rol === "Nuevo estandar" || environment.rol === "Empresarial") {
            window.location.href = environment.rediretPath;
          }else if(environment.rol === "Express"){
            this.router.navigate(["/login-exc"]);
          }else {
            this.router.navigate(["/login"]);
          }
          observer.next(false);
          observer.complete();
        }
        observer.next(true);
        observer.complete();
      });
    });
  }
}
